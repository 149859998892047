@import 'libs/shared/src/lib/utils/utils';
@import 'libs/shared/src/lib/utils/fonts';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Artegra', sans-serif;
}

h1,
h2,
h3 {
  font-family: 'Artegra-Bold';
}

.primary-color {
  color: #6e54fc !important;
}

.accent-color {
  color: #000000;
}

.warning-color {
  color: #ffcd1c;
}

.error-color {
  color: #fc4e69;
}

p {
  b,
  strong {
    font-family: 'Artegra-Bold';
  }
  a {
    font-family: 'Artegra-Semibold';
  }
}

.defaultModal .ant-modal-content {
  min-height: 100vh;
}

// To onboarding modal.
.ant-notification {
  z-index: 999;
}

.desktop {
  @media (max-width: 768px) {
    display: none !important;
  }
}

.mobile {
  @media (min-width: 768px) {
    display: none !important;
  }
}

.buttons-secondary {
  button {
    background-color: white;
    border-color: #6d54fc;
    color: #6d54fc;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
    border-radius: 8px;

    &:disabled {
      opacity: 0.4;
    }
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.loadingPage {
  background-color: #fcfcfe;

  nz-spin {
    width: 220px;
  }
}

.tag {
  width: max-content;
  padding: 4px 8px;
  background: #e7e3ff;
  border-radius: 4px;
  span {
    font-family: 'Poppins';
    @media (min-width: 768px) {
      font-family: 'Inter';
    }

    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #342b78;
  }
}

.padding-2 {
  padding: 24px;

  @media (min-width: 768px) {
    padding: 0;
    margin-top: 20px;
  }
}

.cdk-virtual-scrollable::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  background-color: #f0f2f5 !important;
  border: 1px solid #f0f2f5 !important;
  border-radius: 8px !important;
}

.cdk-virtual-scrollable::-webkit-scrollbar {
  width: 5px !important;
}

.cdk-virtual-scrollable::-webkit-scrollbar-thumb {
  background: #6d54fc !important;
  border-radius: 8px !important;
}

.cdk-virtual-scrollable {
  height: 150px !important;
}

.modal-header {
  border-bottom: none !important;
}

.modal-body {
  display: flex;
  flex-direction: column;
  padding: 0 24px;

  p {
    margin: 0;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
  }
}

.logo-checkout {
  width: 60px;
  height: 35px;
}
